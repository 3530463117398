import React from "react";

export default function Logo() {
  return (
    <div className="Logo h2">
      {" "}
      &#x2039;<span className="primary-text h2">/</span>LP &#x203A;
    </div>
  );
}
