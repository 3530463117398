import React from "react";

import "./_index.scss";
export default function Footer() {
  return (
    <div className="Footer">
      Developed by Loi Pham | Designed by Lynna Huynh
    </div>
  );
}
